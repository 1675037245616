






















































































.aboutOurs {
	.contact {
		padding: 0.3rem 0;
		.ico {
			padding: 1rem 0;
			font-size: .24rem;
			text-align: center;
			img {
				display: block;
				width: 2.67rem;
				// height: 1.44rem;
				margin: auto;
			}
		}

		.contact-item {
			// height: .88rem;
			padding: 0.2rem 0;
			border-bottom: 0.5px solid rgba(211, 217, 222, .5);
			// padding: 0 .25rem;
			font-size: 0.28rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// &:last-child {
			// 	border-bottom: 0;
			// }
			.icon {
				width: 0.16rem;
				height: 0.28rem;
				display: block;
				// margin-right: .12rem;
			}
			.label {
				// margin-right: .25rem;
				color: #333333;
				// font-weight: bold;
				font-size: .28rem;
			}
		}
	}
}

// --------20200411[start]------
.layoutBox {
	height:100vh;
	padding: 0.25rem 0.3rem;
	font-family: PingFangSC-Regular;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;