.billBox {
  font-family: PingFangSC-Regular;
}
.billBox .billNumBox {
  border-bottom: 0.2rem solid #f6f8f9;
}
.billBox .billNum {
  text-align: center;
  padding: 0.4rem 0rem 0.6rem 0rem;
}
.billBox .billNum p {
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}
.billBox .billNum span {
  display: block;
  margin-top: 0.2rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fff;
}
.billBox .cumulative {
  background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
  background-size: 100% 100%;
}
.billBox .today {
  background: url(../../assets/imgs/bill/jryl.png) no-repeat;
  background-size: 100% 100%;
}
.billBox .billList {
  margin: 0 0.2rem;
}
.billBox .billList .top {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.billBox .billList .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background: linear-gradient(131deg, #527EFF 0%, #214BF9 100%);
}
.billBox .billList .top .caption {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.billBox .billList .billItem {
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 0.2rem;
}
.billBox .billList .billItem .billTitle {
  font-weight: 400;
  color: #02172E;
  line-height: 0.4rem;
  padding: 0.25rem 0.25rem 0.15rem;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billBox .billList .billItem .billTitle .t_left {
  display: flex;
  align-items: center;
}
.billBox .billList .billItem .billTitle i {
  margin-right: 0.15rem;
  font-size: 0.29rem;
  vertical-align: 0.01rem;
}
.billBox .billList .billItem .billTitle span {
  float: right;
  font-size: 0.24rem;
  font-family: Bahnschrift;
  font-weight: 300;
  color: #B5B4BA;
}
.billBox .billList .billItem .billTitle .name {
  color: #333333;
  font-size: 0.28rem;
}
.billBox .billList .billItem .billTitle .name1 {
  color: #666666;
  font-size: 0.24rem;
}
.billBox .billList .billItem .billCent {
  position: relative;
  text-align: center;
  padding: 0.2rem 0 0.3rem 0;
}
.billBox .billList .billItem .billCent p {
  font-size: 0.24rem;
  color: #666666;
}
.billBox .billList .billItem .billCent span {
  display: block;
  font-size: 0.28rem;
  color: #333333;
  height: 0.3rem;
}
.billBox .billList .billItem .billCent .billGreen {
  font-size: 0.28rem;
  color: #32BF88;
}
.billBox .billList .billItem .billCent i {
  width: 1.5px;
  height: 0.6rem;
  background: #F5F5F5;
  position: absolute;
  right: 0;
  top: 0.4rem;
}
.billBox .billList .billItem .billCent:last-child {
  padding-right: 0.22rem;
}
.billBox .billList .billItem .billCent1 {
  position: relative;
  text-align: left;
  padding: 0.2rem 0 0.3rem 0;
  padding-left: 0.22rem;
}
.billBox .billList .billItem .billCent1 p {
  font-size: 0.24rem;
  color: #666666;
}
.billBox .billList .billItem .billCent1 span {
  display: block;
  font-size: 0.28rem;
  color: #333333;
  height: 0.3rem;
}
.billBox .billList .billItem .billCent1 .billGreen {
  font-size: 0.28rem;
  color: #23A453;
}
.billBox .billList .billItem .billCent1 i {
  width: 1.5px;
  height: 0.6rem;
  background: #F5F5F5;
  position: absolute;
  right: 0;
  top: 0.4rem;
}
.billBox .billList .billItem:last-child {
  border-bottom: none;
}
.communityInfo {
  background: #FFFFFF;
  position: relative;
  z-index: 2;
}
.communityInfo .list {
  padding: 0.4rem 0;
  margin: ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.communityInfo .list .item {
  width: 50%;
  color: #2E62FF;
  font-size: 0.24rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.communityInfo .list .item .yen {
  color: #2E62FF;
  font-size: 0.36rem;
  font-weight: bold;
  margin-bottom: 0.08rem;
}
.communityInfo .list .item .i_right {
  margin-left: 0.32rem;
}
.communityInfo .list .item .i_right img {
  width: 0.24rem;
  height: 0.24rem;
}
.communityInfo .list .split {
  width: 1px;
  height: 0.6rem;
  background: #E7E7E7;
}
.layoutBox {
  padding-top: 1.6rem;
  margin-top: -1.6rem;
  min-height: calc(100vh - 1.8rem);
  position: relative;
  z-index: 1;
}
