












































































































































































































































































































































































// ------20200409-----
@import url('../../../assets/css/login.less');
.registBox {
	font-family: PingFangSC-Regular;
	// padding-bottom: 1.42rem;
	background-color: #FFFFFF;
	// height: 100vh;
	.head {
		display: flex;
		margin: 0 0.72rem 0 0.72rem;
		padding-top: 0.28rem;
		align-items: center;
		justify-content: space-between;
		.langBtn {
			.icon {
				width: 0.4rem;
				height: 0.4rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					display: block;
					width: 100%;
				}
			}
		}
		.zhuce {
			.txt {
				text-align: center;
				line-height: 0.51rem;
				color: #5569FC;
				font-size: 0.32rem;
				font-weight: 600;
			}
		}
	}
	.registBody {
		padding: 0.88rem 0.67rem 2rem 0.71rem;
		// background: #ffffff;
		.headIcon {
			img {
				width: 2rem;
				display: block;
				margin: 0 auto;
			}
		}
		.agreelaw {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: 400;
			color: #c3c5c8;
			padding-top: 0.4rem;
			.mainColor1{
				color: #EFBA0D;
				// border-bottom: 1px solid #90C4FF;
			}
		}
		.tologin {
			padding-top: 0.2rem;
			font-size: 0.24rem;
			text-align: center;
			color: #999;
			padding-bottom: 0.3rem;
		}
	}
	.registerPage {
		color: #FFFFFF;
	    padding: 0.2rem 0.2rem 0.4rem;
	}
	.titleBtnStyle {
	    background: #FC9216;
	    border: 0px;
	    border-radius: 6px;
	    width: 1.6rem;
	    float: right;
	}
}
.login-dex {
	// margin-top: 1.6rem !important;
	padding-top: 1.2rem;
}

/* 我同意字体在有的分辨率下显示不全的问题 */

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;