.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.titleList {
  font-weight: bold;
  margin: 0 0.3rem;
}
.titleList .top {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.titleList .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background: linear-gradient(131deg, #527EFF 0%, #214BF9 100%);
}
.titleList .top .caption {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.billNum {
  text-align: center;
  padding: 0.4rem 0rem 0.6rem 0rem;
}
.billNum p {
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}
.billNum span {
  display: block;
  margin-top: 0.2rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fff;
}
.cumulative {
  background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
  background-size: 100% 100%;
}
.today {
  background: url(../../assets/imgs/bill/jryl.png) no-repeat;
  background-size: 100% 100%;
}
.bodyLiStyle {
  color: #666666;
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.bodyLiStyle.right {
  width: 2.4rem;
}
.content_list {
  padding: 0.1rem 0;
  background: #ffffff;
  border-radius: 0.32rem;
}
.bodyDivStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0;
  margin: 0 0.4rem;
  border-bottom: 1px solid #ededed;
}
.bodyDivStyle .list_left {
  margin-left: 0.2rem;
}
.bodyDivStyle .list_left .num {
  color: #32BF88;
  font-size: 0.28rem;
  font-weight: bold;
}
.bodyDivStyle .list_left .txt {
  color: #666666;
  font-size: 0.24rem;
}
.bodyDivStyle .list_right {
  margin-right: 0.2rem;
}
.bodyDivStyle .list_right .txt {
  text-align: right;
  color: #666666;
  font-size: 0.24rem;
}
.bodyDivStyle:last-child {
  border-bottom: none;
}
.titleVanRowDivStyle {
  width: 100%;
  background: #ffffff;
}
.communityInfo {
  background: #ffffff;
  position: relative;
  z-index: 2;
}
.communityInfo .list {
  padding: 0.4rem 0;
  margin: ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.communityInfo .list .item {
  width: 50%;
  color: #2E62FF;
  font-size: 0.24rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.communityInfo .list .item .yen {
  color: #2E62FF;
  font-size: 0.36rem;
  font-weight: bold;
  margin-bottom: 0.08rem;
}
.communityInfo .list .item .i_right {
  margin-left: 0.32rem;
}
.communityInfo .list .item .i_right img {
  width: 0.24rem;
  height: 0.24rem;
}
.communityInfo .list .split {
  width: 1px;
  height: 0.6rem;
  background: #e7e7e7;
}
.caption {
  font-size: 0.32rem;
  font-weight: bold;
  color: #373737;
}
.layoutBox {
  position: relative;
  z-index: 1;
}
