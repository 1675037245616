







































































































































































































































































































































































































































// .fade-enter-active, .fade-leave-active {
//   transition: opacity .5s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
// .data {
//   display: flex;
//   width: 100%;
//   margin-top: 0.16rem;
//   flex-direction: row;
//   text-align: center;
// }
// .change{
//   display: flex;
//   width: 100%;
//   // justify-content: space-between;
//   font-size: 0.26rem;
//   padding-top: 0.1rem;
//   padding-bottom: 0.2rem;
//   flex-direction: row;
//   text-align: center;
// }
// .v{
//   flex: 1;
//     margin-left: 3px;
//     font-size: 0.31rem;
//     font-weight: 500;
//     color: #333;
//   }
// .itemChange{
//   color: #888;
//   flex: 1;
// }
// .content{
//   padding-left: 0.6rem;
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   flex: 1;

// }

.header {
  // margin-bottom: 0.2rem;
  border-radius: 0.15rem;
  // height: 0.8rem;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;
  margin-bottom: 0.1rem;
  .kuai {
  	width: 0.1rem;
  	height: 0.26rem;
  	background-color: #5569FC;
	margin-right: 0.12rem;
  }
}
.title {
  // padding: 0.1rem 0.1rem;
  // border-radius: 5px;
  // margin-left: 0.15rem;
  color: #333333;
  font-size: 0.32rem;
  font-family: PingFang SC;
  width: 50%;
}
.header_right {
  width: 50%;
  font-family: PingFang SC;
  // margin-right: 0.2rem;
  color: #373737;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  .right1 {
    width: 40%;
    text-align: right;
  }
  .right2 {
    width: 65%;
    text-align: center;
  }
}
// .placeholder{
//   margin-top: 20px;
// }
// .spe{
//   display: inline-block;
//   height: 0.25rem;
//   border-right: 0.01rem solid rgb(228, 228, 228);
//   margin: 0 0.4rem;
// }
.quantification {
  .listQuan {
    // padding-bottom: 0.5rem;
    // margin: -0.2rem 0.245rem 0;
    // padding: 0.1rem 0;
    background-color: #ffffff;
    border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
    margin-bottom: 0.2rem;
    &:last-child {
      border-bottom: none;
    }
  }
}
.listQuan_1 {
  display: flex;
  justify-content: space-between;
  margin:  0 0.4rem;
  padding-top: 16px;
  align-items: center;
  .listQuan_1_left {
    display: flex;
    align-items: center;
	// width: 32%;
    // width: 50%;
	.csTitle {
		span {
			color: #90C4FF;
			font-size: 0.24rem;
			transform: scale(0.83);
			padding: 0 .1rem;
			line-height: .36rem;
			background: #F1F7FF;
			border-radius: .04rem;
			display: inline-block;
			margin-left: .1rem;
			&.gray {
				color: #9B9B9B;
				background: #F5F5F5;
			}
			&.gray1 {
				color: #F5465D;
				background: rgba(245, 70, 93, 0.09);
				border-radius: 0.04rem;
			}
			&.gray2 {
				color: #EFBA0D;
				background: rgba(255, 250, 232, 1);
				border-radius: 0.04rem;
			}
		}
	}
    .checkbox {
      margin-left: 0.15rem;
      // margin-top: 0.05rem;
      display: flex;
      align-items: center;
    }
    .content_top {
      margin-left: 0.2rem;
      display: flex;
      align-items: flex-end;
      .name {
		font-family: PingFangSC-Medium;
		font-size: 0.28rem;
        color: #333300;
		font-weight: 600;
      }
      .name1 {
		font-family: PingFangSC-Regular;
        font-size: 0.24rem;
        color: #333300;
      }
      // .name2 {
      //   font-size: 0.41rem;
      //   color: #999;
      // }
    }
  }
  .listQuan_1_center {
	  // margin-left: 0.3rem;
	  // width: 32%;
	  
  }
  .listQuan_1_right {
	  // width: 32%;
    display: flex;
    width: 50%;
    justify-content: space-between;
	align-items: center;
	font-family: PingFangSC-Medium;
	.right2 {
	  font-weight: bold;
	  text-align: center;
	  color: #333333;
	  font-size: 0.28rem;
	}
    .right1 {
      // width: 40%;
      text-align: center;
      border-radius: 4px;
      font-size: 0.24rem;
      padding: 7px 6.5px;
      color: #fff;
    }
    .green {
      border: 1px solid #32BF88;
      background-color: #32BF88;
    }
    .red {
      background-color: #F5465D;
      border: 1px solid #F5465D;
    }
  }
}
.listQuan_2 {
	font-family: PingFangSC-Regular;
  margin: 0 0.4rem;
  padding: 0.3rem 0;
  border-bottom: 0.5px solid #EAECEF;
  justify-content: space-around;
  display: flex;
  .listQuan_2_item {
    text-align: center;
    .label {
      font-size: 14px;
      color: #333333;
    }
    .value {
      color: #333333;
      font-size: 14px;
    }
    .green {
      color: #32BF88;
    }
    .red {
      color: #F5465D;
    }
  }
  .listQuan_2_name {
    color: #373737;
    font-size: 0.36rem;
    font-family: PingFang SC;
    display: flex;
    justify-content: space-between;
    height: 0.8rem;
    align-items: center;
  }
  .listQuan_2_value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.8rem;
    .listQuan_2_value_left {
      display: flex;
      align-items: center;
      .chicang {
        font-size: 0.36rem;
        font-weight: 550;
        margin-right: 0.1rem;
      }
      .bucang {
        font-size: 0.22rem;
        color: #373737;
      }
    }
    .listQuan_2_value_right {
      display: flex;
      align-items: center;
      .fiyin {
        font-size: 0.36rem;
        font-weight: 550;
        margin-left: 0.1rem;
      }
      .fudu {
        font-size: 0.24rem;
      }
      .green {
        color: #07ad91;
      }
      .red {
        color: #cf4e65;
      }
    }
  }
}
.listQuan_3 {
  // height: 1rem;
  display: flex;
  padding: 0.14rem 0.25rem 0.08rem 0.15rem;
  display: flex;
  align-items: center;
  .btn{
    text-align: center;
    flex: 1;
    height: 33.5px;
	line-height: 33.5px;
    // padding-top: 2px;
    font-size: 14px;
    color: #6F7989;
    // font-weight: bold;
  }
  .red {
    color: #F5465D !important
  }
  // .listQuan_3_end {
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 25%;
  //   .img {
  //     width: 0.65rem;
  //     height: 0.65rem;
  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;