














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.van-field__control {
	display: block;
	box-sizing: border-box;
	width: 100%;
	min-width: 0;
	margin: 0;
	padding: 0;
	color: #373737;
	// text-align: left;
	text-align: right;
	background-color: transparent;
	border: 0;
	resize: none;
}

.middedStyle {
	color: #a5a5a5;
	background: #f1f1f1;
	font-size: 0.2rem;
	height: 0.8rem;
	padding: 0.15rem;
}

.btnFdivStyle {
	text-align: center;
}

.btnStyle {
	margin-top: 1rem;
	width: 4.2rem;
}

.c_field {
	font-size: 14px;
	padding: 0.2rem 16px;
}

.inputStyle {
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	width: 100%;
	border-bottom: 1px solid #f2f2f2;
	margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	color: #8b7575;
	padding: 0.1rem;
}

.btnOp {
	margin-top: 0rem;
	margin-bottom: 0.1rem;
	text-align: center;
}

.fontStyle {
	font-size: 0.1rem;
	color: #9b9b9b;
	margin: 0.3rem;
}

.iconStyle {
	margin-right: 0.15rem;
}

.ulList>li {
	padding: 0.3rem;
	margin: 0.3rem;
	background: #ffffff;
	border-radius: 5px;
}

.listStyle {
	background: #ffffff;
	border-radius: 5px;
}

.roleBtnStyle {
	margin-left: 0.4rem;
	margin-right: 0.4rem;
	border-radius: 5px;
}

.tpuschool {
	background: #f1f1f3;
	height: 100%;
	width: 100%;
	margin-bottom: 2rem;
}

.whole {
	// background: #ffffff;
}

.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}

.bottomBtnStyle {
	width: 100%;
	background: #e1c364;
	border-radius: 5px;
	border: 0px;
}

.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}

.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

// ----------20200410[start]---------
.layoutBox {
	padding: 0.2rem;
	font-family: PingFangSC-Regular;
}

.robotBox {
	background: white;
	border-radius: .12rem;
	margin-bottom: .2rem;
	overflow: hidden;

	.van-radio--horizontal {
		margin: 0 0 0 0.4rem;
	}
}

.list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .2rem;

	// border-bottom: 1px solid #EDEDED;
	.left {
		color: #6F7A8B;
		display: flex;
		align-items: center;
	}

	.icon {
		margin-right: .2rem;

		img {
			width: 0.44rem;
			display: block;
		}
	}

	.icon1 {
		margin-left: .1rem;

		img {
			width: .355rem;
			display: block;
		}
	}

	.right {
		display: flex;
		align-items: center;
		color: #5569FC;

		.qpxl {
			margin-left: 0.14rem;
			width: 0.18rem;
			height: 0.1rem;
		}
	}

	.right1 {
		display: flex;
		align-items: center;
		color: #5569FC;
	}

	.right2 {
		color: #333333;
	}

	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}

	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}

}

.list-cell1 {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;

	.left {
		color: #666666;
		display: flex;
		align-items: center;
	}

	.icon {
		margin-right: .1rem;

		img {
			width: .48rem;
			display: block;
		}
	}

	.right {
		color: #5569FC;
	}

	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}

	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}

}

.robotBtns {
	display: flex;
	justify-content: space-between;
	padding: 0.2rem 0.3rem;
	.btn {
		font-size: .32rem;
		color: #FFFFFF;
		line-height: .8rem;
		width: 3.26rem;
		height: 0.8rem;
		text-align: center;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		border-radius: .08rem;
	}

}

.tan {
	padding: 0.2rem 0;

	.quedin {
		padding: 0.3rem 1rem;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		text-align: center;
		color: #FFFFFF;
		border-radius: 6px;
		margin-top: 0.3rem;
	}
}

.list_item0 {
	position: fixed;
	z-index: 999;
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.4rem 0;
	width: 90%;
	background-color: rgb(248, 248, 248);
	border-bottom: 1px solid #EDEDED;

	.left {
		.time {
			color: #666666;
			font-size: 0.32rem;
			font-weight: bold;
			align-items: center;
		}
	}

	.icon {
		margin-right: .1rem;

		img {
			width: .48rem;
			display: block;
		}
	}

	.right {
		color: #666666;
		font-size: 0.32rem;
		font-weight: bold;
		align-items: center;
	}

}

.list_item0 {
	position: fixed;
	z-index: 999;
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.4rem 0;
	width: 90%;
	background-color: rgb(248, 248, 248);
	border-bottom: 1px solid #EDEDED;

	.left {
		.time {
			color: #666666;
			font-size: 0.32rem;
			font-weight: bold;
			align-items: center;
		}
	}

	.icon {
		margin-right: .1rem;

		img {
			width: .48rem;
			display: block;
		}
	}

	.right {
		color: #666666;
		font-size: 0.32rem;
		font-weight: bold;
		align-items: center;
	}

}

.tan1 {
	padding: 0.2rem 0;
	padding-top: 1.26rem;
	padding-bottom: 1.2rem;

.list_item {
		font-size: .28rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.4rem .25rem;
		border-bottom: 1px solid #EDEDED;

		.item_left {
			display: flex;
			align-items: center;

			.left {
				color: #666666;
				display: flex;
				align-items: center;
			}

			.icon {
				margin-right: .1rem;

				img {
					width: .48rem;
					display: block;
				}
			}

			.right {
				color: #5569FC;
			}

			.inp1 {
				font-weight: bold;
				height: .48rem;
				border: 0;
				width: 1rem;
				text-align: right;
				background-color: #F8F8F8;
			}
		}

		.item_right {
			display: flex;
			align-items: center;

			.left {
				color: #666666;
				display: flex;
				align-items: center;
			}

			.icon {
				margin-right: .1rem;

				img {
					width: .48rem;
					display: block;
				}
			}

			.right {
				color: #5569FC;
			}

			.inp1 {
				font-weight: bold;
				height: .48rem;
				border: 0;
				width: 0.7rem;
				text-align: right;
				background-color: #F8F8F8;
			}
		}
	}

	.quedin1 {
		position: fixed;
		bottom: 0.1rem;
		z-index: 999;
		padding: 0.3rem 3.12rem;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		text-align: center;
		color: #FFFFFF;
		border-radius: 6px;
	}
}

.xiala {
	margin-left: 0.04rem;

img {
		width: 0.22rem;
		height: 0.14rem;
	}
}

.icon_arrR {
	width: 0.2rem;
	height: 0.2rem;
}

.mid_list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;

	.left {
		margin-left: 0.56rem;
		color: #666666;
		display: flex;
		align-items: center;
	}

	.icon {
		margin-right: .1rem;

		img {
			width: .48rem;
			display: block;
		}
	}

	.right {
		color: #5569FC;
		display: flex;
		align-items: center;

		img {
			margin-left: 0.14rem;
			width: 0.18rem;
			height: 0.1rem;
		}
	}

	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}

	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}

}

// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;